import clsx from "clsx";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SCALEOPS_COLORS } from "../../../colors";
import { useMainContext } from "../../../MainContext";
import FreezeTooltipWarning from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import {
  TooltipTrigger,
  UpdateActiveTooltips,
} from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../utils/styleUtils";
import ChartTooltipElement from "../../ChartTooltipElement";
import useGetNodeAction from "../../unevictable/BlockedNodesTable/useGetNodeAction";
import { BlockedNodeReasons } from "../../unevictable/BlockedNodesTable/utils";
import { getDisplayName, GraphPoint, ROW_DATA } from "./utils";

const COL_GAP_CLASS_NAME = "flex flex-col gap-4";
const TOOLTIP_ID = "nodes-blocker-reasons-tooltip";

interface CustomTooltipProps {
  active?: boolean;
  payload?: { payload: GraphPoint }[];
  label?: string;
  tooltipTrigger: TooltipTrigger | undefined;
  updateActiveTooltips: UpdateActiveTooltips | undefined;
  openInNewTab?: boolean;
}

const CustomTooltip = ({ active, payload, tooltipTrigger, updateActiveTooltips }: CustomTooltipProps) => {
  const { currentCluster } = useMainContext();
  const nodeAction = useGetNodeAction();

  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(TOOLTIP_ID), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(TOOLTIP_ID), false);
    };
  }, [active, updateActiveTooltips]);

  const rawName = String(payload?.[0]?.payload?.name ?? "") as BlockedNodeReasons;
  const name = getDisplayName(rawName);
  const reasons = Object.entries(payload?.[0]?.payload ?? {}).filter(([key]) => key !== "name" && key !== ROW_DATA);

  const nodesPerReason = Object.entries(
    (payload?.[0]?.payload?.rowData as Record<string, Record<string, string[]>>) ?? {}
  ).reduce((acc, [reason, { nodes }]) => {
    acc[reason] = nodes;
    return acc;
  }, {} as Record<string, string[]>);

  const disableNameLink = rawName === BlockedNodeReasons.ExploreNodeRestriction;

  return (
    <div className={clsx(COL_GAP_CLASS_NAME, TOOLTIP_WRAPPER_CLASS_NAME)}>
      <div
        onClick={(e) => {
          e.preventDefault();
          if (!disableNameLink) {
            nodeAction[rawName]({
              node: rawName,
            });
          }
        }}
        className={clsx({ "cursor-pointer hover:text-main-linkBlueHover hover:underline": !disableNameLink })}
      >
        <p style={{ fontWeight: 600 }} className="text-[10px] flex gap-1">
          {name}
        </p>
      </div>
      <div className={COL_GAP_CLASS_NAME}>
        {reasons.map(([reason, count], index) => {
          const color =
            SCALEOPS_COLORS.blockedReason?.[reason as keyof typeof SCALEOPS_COLORS.blockedReason] ??
            SCALEOPS_COLORS.guideline.darkGray;

          return (
            <div>
              <div
                onClick={() => {
                  if (!disableNameLink)
                    nodeAction[rawName]({
                      node: reason,
                      schedulerBlocker: reason,
                    });
                }}
              >
                <ChartTooltipElement
                  key={index}
                  color={color}
                  label={getDisplayName(reason)}
                  value={Number(count)}
                  className={clsx({
                    "hover:text-main-linkBlue hover:underline": !disableNameLink,
                    "cursor-pointer": !disableNameLink,
                  })}
                />
              </div>
              <div className="max-h-[100px] overflow-y-scroll scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
                {nodesPerReason[reason]?.map((node, index) => {
                  const showYamlTab =
                    rawName === BlockedNodeReasons.ExploreNodeRestriction ? "&nodeOverviewSelectedTab=Yaml" : "";
                  return (
                    <NavLink
                      to={`/nodes?currentClusterURLParam=${
                        currentCluster ?? ""
                      }&selectedNodeOverviewId=${node}${showYamlTab}`}
                      key={index}
                      className="flex gap-1 hover:text-main-linkBlue underline"
                    >
                      <p className="text-[10px] text-text-lightBlack">{node}</p>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <FreezeTooltipWarning
        tooltipTrigger={tooltipTrigger}
        frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
        entityType="node"
      />
    </div>
  );
};

export default CustomTooltip;
