import FormSingleSelect from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

const DEFAULT_VALUE = 5;

const options: [string, number][] = [
  ["0 seconds", 0],
  ["5 seconds", 5],
  ["15 seconds", 15],
  ["30 seconds", 30],
  ["60 seconds", 60],
  ["120 seconds", 120],
];
interface Props {
  isCustomizedPolicy?: boolean;
  disabled?: boolean;
}

const ReadinessDelaySettings = ({ isCustomizedPolicy, disabled }: Props) => {
  const disabledInput = disabled || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs);
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Readiness period buffer"
        description="Define a time buffer for pods to become ready, in addition to the existing workload readiness probe."
      />
      <div className="flex flex-wrap gap-4">
        <FormSingleSelect
          label="Buffer period"
          name="minReadyTime"
          options={options}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={disabledInput}
          defaultValue={DEFAULT_VALUE}
          tooltipContent={
            disabledInput ? (
              <p>
                Buffer period unsupported when <b>Ensure high availability</b> is disabled
              </p>
            ) : undefined
          }
        />
      </div>
    </div>
  );
};

export default ReadinessDelaySettings;
