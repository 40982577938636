import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";
import { GetScheduleBlockersGraph, GetScheduleBlockersGraphResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import { PAGE_CONTENT_CLASS_NAME } from "../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../utils/useStateWithLocalStorage";
import { getDisplayName } from "../../autoscalers/ScheduleBlockersGraph/utils";
import BlockedNodesFilters from "./BlockedNodesFilters";
import BlockedNodesTable from "./BlockedNodesTable";
import { QueryParamKey } from "./utils";

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const BLOCKED_REASONS_COST_PAGE_SIZE_LOCAL_STORAGE_KEY = "blockedReasonsCostPageSize";
const { queryKey, queryFn } = GetScheduleBlockersGraph();

const BlockedNodesTableContainer = () => {
  const [existingBlockedReasons, setExistingBlockedReasons] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<
    | (components["schemas"]["NodeGroupsBlockedNodeTableEntry"] & { id: string; action: string | undefined })[]
    | undefined
  >([]);
  const [searchTerm] = useQueryParam(QueryParamKey.BlockedNodesSearchTerm, StringParam);
  const [blockedReasons] = useQueryParam(QueryParamKey.BlockedNodesReasons, ArrayParam);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: BLOCKED_REASONS_COST_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 10,
    valueFormatter: (value) => parseInt(value),
  });

  const { data, isError, isLoading } = useQuery<GetScheduleBlockersGraphResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });

  useEffect(() => {
    if (data?.tableData) {
      const newBlockedReasons: string[] = [
        ...new Set(
          data.tableData
            .map((entry) => entry.blockedReason)
            .filter((reason) => !!reason)
            .map((reason) => String(reason))
            .sort((a, b) => a.localeCompare(b))
        ),
      ];
      setExistingBlockedReasons(newBlockedReasons);
    }

    let newGraphData = data?.tableData
      ?.map((entry) => ({
        ...entry,
        id: String(entry.node),
        action: entry.blockedReason,
      }))
      ?.filter((entry) => {
        if (!searchTerm) {
          return true;
        }

        const search = searchTerm.toLowerCase().trim();
        const blockedReason = getDisplayName(entry?.blockedReason ?? "");

        return entry.node?.toLowerCase().includes(search) || blockedReason?.toLowerCase().includes(search);
      })
      ?.filter((entry) => {
        if (!blockedReasons?.length) {
          return true;
        }

        return entry?.blockedReason && blockedReasons.includes(entry.blockedReason);
      });

    if (newGraphData) {
      newGraphData = newGraphData.sort((a, b) => {
        if (Number(a.savingsAvailable) < Number(b.savingsAvailable)) {
          return 1;
        } else if (Number(a.savingsAvailable) > Number(b.savingsAvailable)) {
          return -1;
        }
        return 0;
      });
    }

    newGraphData = newGraphData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    setGraphData(newGraphData);
  }, [data, searchTerm, blockedReasons, page, pageSize]);

  if (isError) {
    console.log("Error fetching schedule blockers graph data");
    return null;
  }

  return (
    <div className={clsx("flex flex-col gap-4", PAGE_CONTENT_CLASS_NAME)}>
      <Typography variant="body1" fontWeight={700}>
        Blocked nodes
      </Typography>
      <BlockedNodesFilters existingBlockedReasons={existingBlockedReasons} />
      <BlockedNodesTable
        data={graphData}
        isLoading={isLoading}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        rowCount={data?.tableData?.length ?? 0}
      />
    </div>
  );
};

export default BlockedNodesTableContainer;
