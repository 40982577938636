export enum BlockedNodeReasons {
  OptimizeUnevictablePods = "OptimizeUnevictablePods",
  ChangeClusterAutoScalerConfiguration = "ChangeClusterAutoScalerConfiguration",
  ExploreSchedulingReasons = "ExploreSchedulingReasons",
  ExploreNodeRestriction = "ExploreNodeRestriction",
}

export enum QueryParamKey {
  BlockedNodesReasons = "blockedReasons",
  BlockedNodesSearchTerm = "blockedNodesSearchTerm",
}
