import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import DuplicateIcon from "../../../../Icons/DuplicateIcon";
import { useMainContext } from "../../../../MainContext";
import FreezeTooltipWarning from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import {
  TooltipTrigger,
  UpdateActiveTooltips,
} from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import { TOAST_SETTINGS } from "../../../../pages/Roles/mutations/utils";
import { customNumberFormatter } from "../../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import ChartTooltipElement from "../../../ChartTooltipElement";
import { buildNodeOverviewLink } from "../../../common/LinkableTooltipElement";
import { CHART_COLORS, ChartDataEntry, ElementNames, ELEMENTS_ORDER } from "./utils";

const TOOLTIP_ID = "un-evictable-bar-chart";
const ELEMENTS_TOP_DISPLAY = ELEMENTS_ORDER;
const ICON_WIDTH = 12;

ELEMENTS_TOP_DISPLAY.reverse();

interface CustomTooltipProps {
  active?: boolean;
  payload?: { payload: ChartDataEntry }[];
  label?: string;
  tooltipTrigger: TooltipTrigger | undefined;
  updateActiveTooltips: UpdateActiveTooltips | undefined;
  openInNewTab?: boolean;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  tooltipTrigger,
  updateActiveTooltips,
  openInNewTab = true,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(TOOLTIP_ID), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(TOOLTIP_ID), false);
    };
  }, [active, updateActiveTooltips]);

  const { currentCluster } = useMainContext();

  if (active && payload && payload.length) {
    const link = buildNodeOverviewLink(label ?? "", currentCluster);
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        <p style={{ fontWeight: 600 }} className="text-[10px] flex gap-1">
          <DuplicateIcon
            className="cursor-pointer"
            width={ICON_WIDTH}
            height={ICON_WIDTH}
            onClick={(e) => {
              if (label) {
                navigator.clipboard.writeText(label);
                e.stopPropagation();
                e.preventDefault();
                toast.success("Value copied to clipboard", TOAST_SETTINGS);
              }
            }}
          />
          <NavLink
            to={link}
            target={openInNewTab ? "_blank" : undefined}
            className={"hover:text-main-linkBlue underline"}
          >
            {label}
          </NavLink>
        </p>
        {ELEMENTS_TOP_DISPLAY.map((key) => {
          if (payload[0].payload) {
            return (
              <>
                <ChartTooltipElement
                  color={CHART_COLORS[key as keyof typeof CHART_COLORS]}
                  key={key}
                  value={customNumberFormatter(payload[0].payload[key as keyof ChartDataEntry] as number)}
                  label={ElementNames[key as keyof typeof ElementNames]}
                />
              </>
            );
          }
        })}
        <FreezeTooltipWarning
          tooltipTrigger={tooltipTrigger}
          frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
          entityType="node"
        />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
