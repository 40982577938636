import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { DISPLAY_NAME } from "../../../utils/namesUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const AutoHealingSwitch = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title={DISPLAY_NAME.AutoHealingReaction}
        description="ScaleOps will automatically heal and recover pods suffering from CPU stress, out-of-memory issues, and pods with liveness probe errors due to lack of resources."
      />
      <FormSwitch
        name="autoHealingEnabled"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
      />
    </div>
  );
};
export default AutoHealingSwitch;
